import React, { useState } from "react"

import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap"
// import Vector from "./Vectormap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const containerStyle = {
    width: '100%',
    height: '300px',
};

const center = {
    lat: 37.778519, lng: -122.40564
};

const second = {
    lat: 54.5260, lng: 15.2551
}

const third = { lat: 8.7832, lng: 34.5085 }

const fourth = { lat: 19.0760, lng: 72.8777 }

const MapsGoogle = (props) => {
    document.title = "Maps | Samply - React Admin & Dashboard Template";

    const selectedPlace = {}

    const [selected, setSelected] = useState(null);

    const onSelect = (marker) => {
        setSelected(marker);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Maps" breadcrumbItem="Google Maps" />
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Markers</h4>
                                    <p className="card-title-desc">Example of google maps.</p>
                                </CardHeader>
                                <CardBody>
                                    <div id="gmaps-markers" className="gmaps" style={{ position: "relative" }}>
                                        <LoadScript googleMapsApiKey="AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE">
                                            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={14}>
                                                <Marker position={center} onClick={() => onSelect(center)} />
                                                {selected && (
                                                    <InfoWindow
                                                        position={selected}
                                                        onCloseClick={() => setSelected(null)}
                                                    >
                                                        <div>
                                                            <h1>{selectedPlace.name}</h1>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Overlays</h4>
                                    <p className="card-title-desc">Example of google maps.</p>
                                </CardHeader>
                                <CardBody>
                                    <div id="gmaps-overlay" className="gmaps" style={{ position: "relative" }}>
                                        <LoadScript googleMapsApiKey="AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE">
                                            <GoogleMap mapContainerStyle={containerStyle} center={second} zoom={14}>
                                                <Marker position={second} onClick={() => onSelect(second)} />
                                                {selected && (
                                                    <InfoWindow
                                                        position={selected}
                                                        onCloseClick={() => setSelected(null)}
                                                    >
                                                        <div>
                                                            <h1>{selectedPlace.name}</h1>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Street View Panoramas</h4>
                                    <p className="card-title-desc">Example of google maps.</p>
                                </CardHeader>
                                <CardBody>
                                    <div id="panorama" className="gmaps-panaroma" style={{ position: "relative" }}>
                                        <LoadScript googleMapsApiKey="AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE">
                                            <GoogleMap mapContainerStyle={containerStyle} center={third} zoom={8}>
                                                <Marker position={third} onClick={() => onSelect(third)} />
                                                {selected && (
                                                    <InfoWindow
                                                        position={selected}
                                                        onCloseClick={() => setSelected(null)}
                                                    >
                                                        <div>
                                                            <h1>{selectedPlace.name}</h1>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title">Map Types</h4>
                                    <p className="card-title-desc">Example of google maps.</p>
                                </CardHeader>
                                <CardBody>
                                    <div id="gmaps-types" className="gmaps" style={{ position: "relative" }}>
                                        <LoadScript googleMapsApiKey="AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE">
                                            <GoogleMap mapContainerStyle={containerStyle} center={fourth} zoom={8}>
                                                <Marker position={fourth} onClick={() => onSelect(fourth)} />
                                                {selected && (
                                                    <InfoWindow
                                                        position={selected}
                                                        onCloseClick={() => setSelected(null)}
                                                    >
                                                        <div>
                                                            <h1>{selectedPlace.name}</h1>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}


// class MapsGoogle extends Component {
//     constructor(props) {
//     document.title = "Maps | Samply - React Admin & Dashboard Template";

//         super(props)

//         this.state = {
//             showingInfoWindow: false,
//             activeMarker: {},
//             selectedPlace: {},
//         }
//         this.onMarkerClick = this.onMarkerClick.bind(this)
//     }

//     onMarkerClick() {
//         alert("You clicked in this marker")
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="page-content">
                    
//                     <div className="container-fluid">
//                         <Breadcrumbs title="Maps" breadcrumbItem="Maps" />

//                         <Row>
//                             <Col lg={6}>
//                                 <Card>
//                                     <CardBody>
//                                         <CardTitle className="h4">Google Markers</CardTitle>
//                                         <p className="card-title-desc">
//                                             Example of google maps.
//                                         </p>
//                                         <div
//                                             id="gmaps-markers"
//                                             className="gmaps"
//                                             style={{ position: "relative" }}
//                                         >
//                                             <Map
//                                                 google={this.props.google}
//                                                 style={{ width: "100%", height: "100%" }}
//                                                 zoom={14}
//                                             >
//                                                 <Marker
//                                                     title={"The marker`s title will appear as a tooltip."}
//                                                     name={"SOMA"}
//                                                     position={{ lat: 37.778519, lng: -122.40564 }}
//                                                 />
//                                                 <Marker name={"Dolores park"} />
//                                                 <InfoWindow>
//                                                     <div>
//                                                         <h1>{this.state.selectedPlace.name}</h1>
//                                                     </div>
//                                                 </InfoWindow>
//                                             </Map>
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>

//                             <Col lg={6}>
//                                 <Card>
//                                     <CardBody>
//                                         <CardTitle className="h4">Google Overlays</CardTitle>
//                                         <p className="card-title-desc">
//                                             Example of google maps.
//                                         </p>
//                                         <div
//                                             id="gmaps-overlay"
//                                             className="gmaps"
//                                             style={{ position: "relative" }}
//                                         >
//                                             <Map
//                                                 google={this.props.google}
//                                                 zoom={14}
//                                                 style={{ width: "100%", height: "100%" }}
//                                                 initialCenter={{
//                                                     lat: 40.854885,
//                                                     lng: -88.081807,
//                                                 }}
//                                             >
//                                                 <Marker onClick={this.onMarkerClick} />
//                                                 <InfoWindow>
//                                                     <div>
//                                                         <h1>{this.state.selectedPlace.name}</h1>
//                                                     </div>
//                                                 </InfoWindow>
//                                             </Map>
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                         </Row>

//                         <Row>
//                             <Col lg={6}>
//                                 <Card>
//                                     <CardBody>
//                                         <CardTitle className="h4">Google Basic</CardTitle>
//                                         <p className="card-title-desc">
//                                             Example of google maps.
//                                         </p>
//                                         <div
//                                             id="gmaps-markers"
//                                             className="gmaps"
//                                             style={{ position: "relative" }}
//                                         >
//                                             <Map
//                                                 google={this.props.google}
//                                                 zoom={14}
//                                                 style={{ width: "100%", height: "100%" }}
//                                             >
//                                                 <InfoWindow>
//                                                     <div>
//                                                         <h1>{this.state.selectedPlace.name}</h1>
//                                                     </div>
//                                                 </InfoWindow>
//                                             </Map>
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>

//                             <Col lg={6}>
//                                 <Card>
//                                     <CardBody>
//                                         <CardTitle className="h4">Ultra Light</CardTitle>
//                                         <p className="card-title-desc">
//                                             Example of google maps.
//                                         </p>
//                                         <div
//                                             id="gmaps-overlay"
//                                             className="gmaps"
//                                             style={{ position: "relative" }}
//                                         >
//                                             <Map
//                                                 google={this.props.google}
//                                                 zoom={14}
//                                                 styles={lightMapData.Data}
//                                                 style={{ width: "100%", height: "100%" }}
//                                             >
//                                                 <Marker onClick={this.onMarkerClick} />
//                                                 <InfoWindow>
//                                                     <div>
//                                                         <h1>{this.state.selectedPlace.name}</h1>
//                                                     </div>
//                                                 </InfoWindow>
//                                             </Map>
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <Col xl={12}>
//                                 <Card>
//                                     <CardBody>
//                                         <h4 className="card-title">World Vector Map</h4>
//                                         <p className="card-title-dsec">Example of united kingdom vector maps.</p>
//                                         <div id="uk-vectormap" style={{ height: '400px' }}>
//                                             <Vector
//                                                 value="world_mill"
//                                                 width="500"
//                                                 color="rgb(98, 110, 212)"
//                                             />
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>

//                             <Col lg={6}>
//                                 <Card>
//                                     <CardBody>
//                                         <h4 className="card-title">USA Vector Map</h4>
//                                         <p className="card-title-dsec">Example of united states of ameria vector maps.</p>
//                                         <div id="canada-vectormap" style={{ height: '400px' }}>
//                                             <Vector
//                                                 value="us_aea"
//                                                 width="500"
//                                                 color="rgb(98, 110, 212)"
//                                             />
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                             <Col lg={6}>
//                                 <Card>
//                                     <CardBody>
//                                         <CardTitle>Asia Vector Map</CardTitle>
//                                         <CardSubtitle className="mb-3">
//                                             Example of vector map.
//                                         </CardSubtitle>

//                                         <div id="chicago" className="vector-map-height" style={{ height: '400px' }}>
//                                             <Vector
//                                                 value="asia_mill"
//                                                 width="500"
//                                                 color="rgb(98, 110, 212)"
//                                             />
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </div>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }

// MapsGoogle.propTypes = {
//     google: PropTypes.any
// }

export default MapsGoogle
