import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

//import Images
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";
import img8 from "../../assets/images/small/img-8.jpg";

//Import Light box
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { GalleryData } from "../../common/data/gallery";
import { Link } from "react-router-dom";

const Gallery = () => {
  document.title = "Gallery | Samply - React Admin & Dashboard Template";

  const [displayCategory, setCategory] = useState("All");
  const [Modal, setModal] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);

  const images = [img1, img2, img3, img4, img5, img6, img7, img8];

  const [menu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Pages" breadcrumbItem="Gallery" />

        <Card className="gallery">
          <CardBody>
            <Row>
              <Col lg={12}>
                <div className="justify-content-between d-flex align-items-center">
                  <h4 className="font-size-16 fw-semibold mb-0">
                    Gallery Photos
                  </h4>
                  <Dropdown isOpen={menu} onClick={toggleMenu}>
                    <DropdownToggle tag="a" className="fs-3 text-muted" to="#">
                      <i className="mdi mdi-dots-vertical"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem className="dropdown-item" to="#">
                        Edit
                      </DropdownItem>
                      <DropdownItem className="dropdown-item" to="#">
                        Action
                      </DropdownItem>
                      <DropdownItem className="dropdown-item" to="#">
                        Remove
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <hr className="text-muted" />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="text-center py-2">
                  <ul
                    className="list-inline categories-filter gallery-btn hstack gap-4 justify-content-center flex-wrap"
                    id="filter"
                  >
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        onClick={() => setCategory("All")}
                        className={
                          displayCategory === "All"
                            ? "categories fw-semibold active"
                            : "categories"
                        }
                      >
                        All
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        onClick={() => setCategory("project")}
                        className={
                          displayCategory === "project"
                            ? "categories active"
                            : "categories"
                        }
                      >
                        Project
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        onClick={() => setCategory("designing")}
                        className={
                          displayCategory === "designing"
                            ? "categories active"
                            : "categories"
                        }
                      >
                        Designing
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        onClick={() => setCategory("photography")}
                        className={
                          displayCategory === "photography"
                            ? "categories active"
                            : "categories"
                        }
                      >
                        Photography
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        onClick={() => setCategory("development")}
                        className={
                          displayCategory === "development"
                            ? "categories active"
                            : "categories"
                        }
                      >
                        Development
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>

            <Row className="gallery-wrapper">
              {GalleryData.filter(
                ({ category }) =>
                  category.includes(displayCategory) ||
                  displayCategory === "All"
              ).map(({ title, image, author }, key) => (
                <Col key={key} xl={3} sm={6} className="element-item">
                  <Card className="gallery-box">
                    <div className="gallery-container">
                      <Link
                        className="image-popup"
                        to={image}
                        title={title}
                        onClick={(event) => {
                          event.preventDefault();
                          setModal(!Modal);
                          setphotoIndex(key);
                        }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          src={image}
                          alt={title}
                        />
                      </Link>
                    </div>

                    <div className="box-content p-3">
                      <h5 className="title font-size-17">{title}</h5>
                      <p className="font-size-13 mb-0">
                        by{" "}
                        <Link to="#" className="text-muted">
                          {author}
                        </Link>
                      </p>
                    </div>
                  </Card>
                </Col>
              ))}
              {/* lightbox for portfolio images */}
              {Modal && (
                <Lightbox
                  open={Modal}
                  close={() => setModal(false)}
                  index={photoIndex}
                  slides={images.map((image) => ({ src: image }))}
                />
              )}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Gallery;
