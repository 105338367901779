import React from "react";
import {
  Col,
  Container,
  Row,
  NavLink,
} from "reactstrap";
// import 'react-drawer/lib/react-drawer.css';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//import Components
import Buttons from "./Buttons";
import Badges from "./Badges";
import Dropdowns from "./Dropdowns";
import Images from "./Images";
import Cards from "./Cards";
import TabsAccordions from "./Tabs&Accordions";
import Modals from "./Modals";
import Pagination from "./Pagination";
import Progress from "./Progress";
import TooltipsPopovers from "./Tooltips&Popovers";
import Spinners from "./Spinners";
import Grid from "./Grid";
import Video from "./Video";
import Collapses from "./Collapse";
import Placeholder from "./Placeholder";
import Utilities from "./Utilities";
import UiOffcanvas from "./Offcanvas";

const UiElements = () => {
  document.title = "UI Elements | Samply - React Admin & Dashboard Template";
 
  return (
    <React.Fragment>
      <div className="page-content">
        
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Ui Elements" breadcrumbItem="Ui Elements" />
          <Row>
            <Col lg={12}>
              <div className="d-xl-flex">
              <div className="w-100" data-bs-spy="scroll" data-bs-target="#navbar-example2">
                  <div>
                    <Row>
                      <Buttons />
                    </Row>

                    <Row>
                      <Badges />
                    </Row>

                    <Row>
                      <Dropdowns />
                    </Row>

                    <Row>
                      <Images />
                    </Row>

                    <Row>
                      <Cards />
                    </Row>

                    <Row>
                      <Collapses />
                    </Row>

                    <Row>
                      <TabsAccordions />
                    </Row>

                    <Row>
                      <UiOffcanvas />
                    </Row>

                    <Row>
                      <Modals />
                    </Row>

                    <Row>
                      <Pagination />
                    </Row>

                    <Row>
                      <Placeholder />
                    </Row>

                    <Row>
                      <Progress />
                    </Row>

                    <Row>
                      <TooltipsPopovers />
                    </Row>

                    <Row>
                      <Spinners />
                    </Row>

                    <Row>
                      <Grid />
                    </Row>

                    <Row>
                      <Video />
                    </Row>

                    <Row>
                      <Utilities />
                    </Row>

                  </div>
                </div>

                <div className="d-none d-lg-block">
                  <div className="ui-elements-demo-bar card">
                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <NavLink href="#buttons">Buttons</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#badges">Badges</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#dropdowns">Dropdowns</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#images">Images</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#cards">Cards</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#collapse">Collapse</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#tabs-accordions">
                          Tabs & Accordions
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#offcanvas">
                          Offcanvas
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#modals">Modals</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#pagination">Pagination</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#placeholder">Placeholder</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#progress">Progress</NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink href="#tooltip-popover">
                          Tooltips & Popovers
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#spinner">Spinners</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#grid">Grid</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#video">Video</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink href="#utilities">Utilities</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
    </React.Fragment>
  );
};

export default UiElements;
